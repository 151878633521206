import ApiService from "@/core/services/api.service";

// action types
export const POST_PROGRESS_DATA = "postProgressData";

// mutation types
export const SET_ERROR = "setError";

const state = {
    errors: null,
};

const getters = {

};

const actions = {
    [POST_PROGRESS_DATA](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("emmaprogress/createOrUpdate", params)
                .then(({ data }) => {

                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = [];
        state.errors.push(error);
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};