import ApiService from "@/core/services/api.service";

// action types
export const GET_HOME_DATA = "getHomeData";
export const GET_DETAILS_DATA = "getDetailsData";
export const GET_QUESTION_DATA = "getQuestionData";
export const START_TIMER = "startTimer";

// mutation types
export const SET_HOME_DATA = "setHomeData";
export const SET_DETAILS_DATA = "setDetailsData";
export const SET_QUESTION_DATA = "setQuestionData";
export const SET_ERROR = "setError";
export const SET_START_TIMER = "setStartTimer";

const state = {
    errors: null,
    homeData: {},
    recordingTime: 0,
    detailsData: {},
    questionData: {},
};

const getters = {
    detailsData(state) {
        return state.detailsData;
    },
    homeData(state) {
        return state.homeData;
    },
    questionData(state) {
        return state.questionData;
    }
};

const actions = {
    [GET_HOME_DATA](context) {
        return new Promise((resolve, reject) => {
            ApiService.get("artvideos")
                .then(({ data }) => {
                    console.log(data.data.lessons);
                    context.commit(SET_HOME_DATA, data.data.videos);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [GET_QUESTION_DATA](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("artvideo/" + id + "/quizs")
                .then(({ data }) => {
                    context.commit(SET_QUESTION_DATA, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },

    [GET_DETAILS_DATA](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("artvideo/" + id)
                .then(({ data }) => {
                    context.commit(SET_DETAILS_DATA, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },

    [START_TIMER](context) {
        context.commit(SET_START_TIMER);
    }
};

const mutations = {
    [SET_DETAILS_DATA](state, data) {
        state.detailsData = data;
    },
    [SET_HOME_DATA](state, data) {
        state.homeData = data;
    },
    [SET_QUESTION_DATA](state, data) {
        state.questionData = data.quizs;
    },
    [SET_ERROR](state, error) {
        state.errors = [];
        state.errors.push(error);
    },
    [SET_START_TIMER](state) {
        if (state.recordingTime > 0) {
            return;
        }
        state.recordingTime += 1;
        setInterval(
            function() {
                ApiService.get("/account/emmatime/update");
            },
            30000,
        )
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};